
















































































import { Component, Vue } from 'vue-property-decorator'
import FormError from '@bertazzoni/common/src/components/FormError.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import AuthService from '@bertazzoni/common/src/services/AuthService'

@Component({
  components: {
    FormError,
    Icons
  }
})
export default class NewPassword extends Vue {
  private passwordBis = ''
  private password = ''
  private errorMessage = ''
  private loading = false
  private regexPassword = new RegExp(process.env.VUE_APP_PASSWORD_REGEX)

  onSubmit() {
    this.$validator.validateAll().then((isValid) => {
      if (!isValid) {
        return
      }
      this.submitNewPassword()
    })
  }

  async submitNewPassword() {
    this.loading = true
    try {
      await AuthService.newPw(
        this.$route.params.id,
        this.password,
        this.$route.query.token as string
      )
      this.$router.push('/login')
    } catch (error) {
      this.errorMessage = error.message
    }
    this.loading = false
  }
}
